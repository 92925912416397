<template>
    <div class="video">
        <iframe v-if="bgVr" :src="bgVr" frameborder="0" width="100%" height='100%' class="iframe"></iframe>
        <img src="../assets/image/new_return.png" alt="" class="go-back" @click="handleGoBack">
        <div class="video-content">
            <video :src="videoUrl" style="width:100%;height:100%;background:rgba(0,0,0,.6)" ref="video" controls
                :poster="QINIU_CDN_HOST+poster" @pause="pause" @play="play"></video>
            <img src="../assets/image/pause_icon.png" alt="播放" class="pause" @click="handlePlay" v-if="imgIsShow">
            <div class="name-content">
                <div :class="[activeBtn == index?'active-box':'','name-box']" v-for="(item,index) in videoData"
                    :key="index" @click="handleChangeVideo(index)">{{item.title}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                videoData: [],
                videoUrl: '',
                poster: "",
                imgIsShow: true,
                activeBtn: 0,
                bgImg: '',
                bgType: 0,
                beforePage: false
            };
        },
        beforeRouteEnter(to, from, next) {
            if (from.name == null) {
                next(vm => {
                    vm.beforePage = true
                })
            }else{
                next()
            }
        },
        methods: {
            handleGoBack() {
                if (!this.beforePage) {
                    this.$router.go(-1)
                } else {
                    this.$router.push({
                        path: "/"
                    })
                }
            },
            getData() {
                this.$axios({
                    method: "POST",
                    url: "api/v5/panorama/channelDetails",
                    data: {
                        panorama_id: this.$route.query.panorama_id,
                        channel_id: this.$route.query.channel_id
                    }
                }).then(res => {
                    this.videoData = res.data.data;
                    this.videoUrl = res.data.data[0].url
                    this.poster = res.data.data[0].image
                })
            },
            handlePlay(e) {
                this.imgIsShow = false;
                this.$refs.video.play()
            },
            pause() {
                this.imgIsShow = true;
            },
            play() {
                this.imgIsShow = false;
            },
            handleChangeVideo(i) {
                this.$refs.video.pause()
                this.imgIsShow = true;
                this.activeBtn = i;
                this.videoUrl = this.videoData[i].url;
                this.poster = this.videoData[i].image
            }
        },
        created() {
            this.getData();
            this.bgImg = localStorage.getItem("bgImg");
            this.bgVr = localStorage.getItem("bgVr");
        },
    };
</script>
<style scoped lang='scss'>
    .video {
        /* position:absolute;
        top:0;
        left:0; */
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        /* background: #b09455	; */
        /* background: url('https://static.comegogogo.com/panoramaHomerectangle.png') no-repeat;
        background-size: 100% 100%; */
        /* z-index:1; */
        .go-back {
            position: absolute;
            width: 44px;
            height: 36px;
            top: 77px;
            left: 72px;
            cursor: pointer;
            z-index: 2;
        }

        .iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: -2;
        }

        .video-content {
            position: relative;
            padding: 20px;
            width: 1400px;
            height: 680px;
            background: rgba(0, 0, 0, .6);
            border-radius: 17px;
            box-sizing: border-box;

            .pause {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100px;
                height: 100px;
                transform: translate(-50%, -50%);
                cursor: pointer;
            }

            .name-content {
                display: flex;
                position: absolute;
                bottom: -80px;
                left: 50%;
                transform: translateX(-50%);

                .name-box {
                    margin-left: 10px;
                    width: 220px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    font-size: 26px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    background: #333;
                    border-radius: 10px;
                    cursor: pointer;
                    opacity: .8;

                    &:first-of-type {
                        margin-left: 0;
                    }

                }

                .active-box {

                    color: #333;
                    background: #fff;
                }
            }
        }
    }
</style>